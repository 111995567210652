const menu = {
  menuAll: [{
      path: 'dataCenter',
      icon: 'el-icon-pie-chart',
      label: '数据中心',
      name: 'dataCenter',
    },
    {
      path: 'personManage',
      icon: 'el-icon-s-help',
      label: '长者档案',
      name: 'PersonManage',
    },
    {
      path: 'workPerson',
      icon: 'el-icon-user-solid',
      label: '从业人员',
      name: 'WorkPerson',
    },
    {
      path: 'serveRecords',
      icon: 'el-icon-s-order',
      label: '服务记录',
      name: 'ServeRecords',
    },
    {
      path: 'serveOrgs',
      icon: 'el-icon-place',
      name: 'ServeOrgs',
      label: '服务企业',
    //   children: [{
    //     path: 'serveOrgs',
    //     icon: 'el-icon-office-building',
    //     label: '服务企业',
    //     name: 'ServeOrgs',
    //   }
    // ],
    },
    // {
    //   path: 'volunteerManagement',
    //   icon: 'fa fa-book',
    //   label: '志愿者管理',
    //   name: 'volunteerManagement',
    //   children: [
    //     {
    //       path: 'volunteerInfo',
    //       icon: 'el-icon-office-building',
    //       label: '志愿者档案',
    //       name: 'volunteerInfo',
    //     },
    //     {
    //       path: 'volunteerAct',
    //       icon: 'el-icon-office-building',
    //       label: '志愿者活动',
    //       name: 'volunteerAct',
    //     },
    //   ],
    // },
    {
      path: 'nursingHome/mechaismManage/sumCenter',
      icon: 'el-icon-sunny',
      label: '日间照料中心',
      name: 'SumCenter',
    },
    {
      path: 'smartElderlyPlat',
      icon: 'fa fa-book',
      label: '智能看护管理平台',
      name: 'smartElderlyPlat',
      children: [
        {
          path: 'dataFlash',
          icon: '',
          label: '数据快报',
          name: 'dataFlash',
        },
        {
          path: 'smartElderly',
          icon: '',
          label: '智能看护',
          name: 'smartElderly',
        },
      ],
    },
    {
      path: 'assessManage/personAssess',
      icon: 'el-icon-office-building',
      label: '人员评估',
      name: 'personAssess',
    },
    {
      path: 'assessManage/personRecord',
      icon: 'el-icon-office-building',
      label: '养老顾问服务报告',
      name: 'personRecord',
    },
    {
      path: 'oldChange',
      icon: 'el-icon-office-building',
      label: '适老化改造',
      name: 'oldChange',
    },
    // {
    //   path: 'assessManage',
    //   icon: 'el-icon-place',
    //   name: 'assessManage',
    //   label: '评估管理',
    //   children: [{
    //     path: 'assessManage/personAssess',
    //     icon: 'el-icon-office-building',
    //     label: '人员评估',
    //     name: 'personAssess',
    //   },
    //   {
    //     path: 'assessManage/personRecord',
    //     icon: 'el-icon-office-building',
    //     label: '养老顾问服务报告',
    //     name: 'personRecord',
    //   },
    // ],
    // },
    // {
    //   path: 'nursingHome/medicalInst',
    //   icon: 'el-icon-suitcase',
    //   label: '社区医疗机构',
    //   name: 'MedicalInst',
    // },
    // {
    //   path: 'nursingHome',
    //   icon: 'fa fa-book',
    //   name: 'nursingHome',
    //   label: '社区养老',
    //   children: [{
    //       path: 'nursingHome/dataCenter',
    //       icon: 'el-icon-location',
    //       label: '数据中心',
    //       name: 'NursingHomeDataCenter',
    //     },
    //     {
    //       path: 'nursingHome/mechaismManage/generalServiceCenter',
    //       icon: 'el-icon-location',
    //       label: '综合养老服务中心',
    //       name: 'GeneralServiceCenter',
    //     },
    //     {
    //       path: 'nursingHome/mechaismManage/sumCenter',
    //       icon: 'el-icon-location',
    //       label: '日间照料中心',
    //       name: 'SumCenter',
    //     },
    //     {
    //       path: 'nursingHome/mechaismManage/serviceStation',
    //       icon: 'el-icon-location',
    //       label: '居家养老服务站',
    //       name: 'ServiceStation',
    //     },
    //     {
    //       path: 'nursingHome/medicalInst',
    //       icon: 'el-icon-location',
    //       label: '社区医疗机构',
    //       name: 'MedicalInst',
    //     },{
    //       path: 'happyHouse',
    //       icon: 'el-icon-location',
    //       label: '农村幸福院',
    //       name: 'HappyHouse',
    //     }
    //   ],
    // },
    // {
    //   path: 'AgedEat',
    //   icon: 'fa fa-book',
    //   name: 'AgedEat',
    //   label: '老年餐',
    //   children: [{
    //       path: 'AgedEat/mechaismManage',
    //       icon: 'el-icon-location',
    //       label: '机构管理',
    //       name: 'AgedEatmechaismManage',
    //     },
    //     {
    //       path: 'AgedEat/workPerson',
    //       icon: 'el-icon-location',
    //       label: '运营日报',
    //       name: 'operateDailys',
    //     },
    //     {
    //       path: 'AgedEat/dataCenter',
    //       icon: 'el-icon-location',
    //       label: '数据中心',
    //       name: 'AgedEatdataCenter',
    //     },
    //     // {
    //     //   path: 'AgedEat/manManage/manManage',
    //     //   icon: 'el-icon-location',
    //     //   label: '从业人员',
    //     //   name: 'manManage',
    //     // },
    //     {
    //       path: 'AgedEat/manManage/MealRecord',
    //       icon: 'el-icon-location',
    //       label: '用餐记录111111',
    //       name: 'operateDaily',
    //     },
    //     {
    //       path: 'AgedEat/mealCard',
    //       icon: 'el-icon-location',
    //       label: '饭卡管理',
    //       name: 'mealCard',
    //     }
    //   ],
    // },
    // {
    //   path: 'homeCareBeds',
    //   icon: 'fa fa-book',
    //   name: 'homeCareBeds',
    //   label: '家庭养老床位11',
    //   children: [{
    //       path: 'homeCareBeds/bedCompany',
    //       icon: 'el-icon-location',
    //       label: '施工单位',
    //       name: 'BedCompany',
    //     },
    //     {
    //       path: 'homeCareBeds/bedPerson',
    //       icon: 'el-icon-location',
    //       label: '人员信息',
    //       name: 'BedPerson',
    //     },
    //   ],
    // },
    // {
    //   path: 'agedCounselor',
    //   icon: 'fa fa-book',
    //   name: 'agedCounselor',
    //   label: '养老顾问',
    //   children: [{
    //       path: 'agedCounselor/personnelManage/personnelManage',
    //       icon: 'el-icon-location',
    //       label: '人员管理',
    //       name: 'agedCounselorPersonnelManage',
    //     },
    //     {
    //       path: 'agedCounselor/serveRecord',
    //       icon: 'el-icon-location',
    //       label: '服务记录',
    //       name: 'agedCounselorServeRecord',
    //     },
    //   ],
    // },
    // {
    //   path: 'agedMarket',
    //   icon: 'fa fa-book',
    //   name: 'agedMarket',
    //   label: '养老早市',
    //   children: [{
    //       path: 'agedMarket/agedMarketData',
    //       icon: 'el-icon-location',
    //       label: '数据中心',
    //       name: 'agedMarketPersonnelManage',
    //     },
    //     {
    //       path: 'agedMarket/agedMarketList',
    //       icon: 'el-icon-location',
    //       label: '机构列表',
    //       name: 'agedMarketServeList',
    //     },
    //   ],
    // },
  ],
  // menuOne: [{
  //   path: 'nursingHome',
  //   icon: 'fa fa-book',
  //   name: 'nursingHome',
  //   label: '社区养老',
  //   children: [{
  //       path: 'nursingHome/dataCenter',
  //       icon: 'el-icon-location',
  //       label: '数据中心',
  //       name: 'NursingHomeDataCenter',
  //     },
  //     {
  //       path: 'nursingHome/mechaismManage/generalServiceCenter',
  //       icon: 'el-icon-location',
  //       label: '综合养老服务中心',
  //       name: 'GeneralServiceCenter',
  //     },
  //     {
  //       path: 'nursingHome/mechaismManage/sumCenter',
  //       icon: 'el-icon-location',
  //       label: '日间照料中心',
  //       name: 'SumCenter',
  //     },
  //     {
  //       path: 'nursingHome/mechaismManage/serviceStation',
  //       icon: 'el-icon-location',
  //       label: '居家养老服务站',
  //       name: 'ServiceStation',
  //     },
  //     {
  //       path: 'nursingHome/medicalInst',
  //       icon: 'el-icon-location',
  //       label: '社区医疗机构',
  //       name: 'MedicalInst',
  //     }
  //   ],
  // }],
  menuOne: [{
    path: 'nursingHome/mechaismManage/sumCenter',
    icon: 'el-icon-sunny',
    label: '日间照料中心',
    name: 'SumCenter',
    // children: [{
    //     path: 'nursingHome/dataCenter',
    //     icon: 'el-icon-location',
    //     label: '数据中心',
    //     name: 'NursingHomeDataCenter',
    //   },
    //   {
    //     path: 'nursingHome/mechaismManage/generalServiceCenter',
    //     icon: 'el-icon-location',
    //     label: '综合养老服务中心',
    //     name: 'GeneralServiceCenter',
    //   },
    //   {
    //     path: 'nursingHome/mechaismManage/sumCenter',
    //     icon: 'el-icon-location',
    //     label: '日间照料中心',
    //     name: 'SumCenter',
    //   },
    //   {
    //     path: 'nursingHome/mechaismManage/serviceStation',
    //     icon: 'el-icon-location',
    //     label: '居家养老服务站',
    //     name: 'ServiceStation',
    //   },
    //   {
    //     path: 'nursingHome/medicalInst',
    //     icon: 'el-icon-location',
    //     label: '社区医疗机构',
    //     name: 'MedicalInst',
    //   }
    // ],
  }],
  menuTwo: [{
    path: 'AgedEat',
    icon: 'fa fa-book',
    name: 'AgedEat',
    label: '老年餐',
    children: [{
        path: 'AgedEat/mechaismManage',
        icon: 'el-icon-location',
        label: '机构管理',
        name: 'AgedEatmechaismManage',
      },
      // {
      //   path: 'AgedEat/workPerson',
      //   icon: 'el-icon-location',
      //   label: '运营日报',
      //   name: 'operateDailys',
      // },
      // {
      //   path: 'AgedEat/dataCenter',
      //   icon: 'el-icon-location',
      //   label: '数据中心',
      //   name: 'AgedEatdataCenter',
      // },
      {
        path: 'AgedEat/manManage/manManage',
        icon: 'el-icon-location',
        label: '从业人员',
        name: 'manManage',
      },
      {
        path: 'AgedEat/manManage/MealRecord',
        icon: 'el-icon-location',
        label: '用餐记录',
        name: 'operateDaily',
      },
      {
        path: 'AgedEat/mealCard',
        icon: 'el-icon-location',
        label: '饭卡管理',
        name: 'mealCard',
      }
    ],
  }],
  menuThree: [{
    path: 'adaptationToAging',
    icon: 'fa fa-book',
    name: 'adaptationToAging',
    label: '适老化改造',
    children: [{
        path: 'adaptationToAging/chgCompany',
        icon: 'el-icon-location',
        label: '施工单位',
        name: 'ChgCompany',
      },
      {
        path: 'adaptationToAging/chgPerson',
        icon: 'el-icon-location',
        label: '人员信息',
        name: 'ChgPerson',
      },
    ],
  }],
  menuFour: [{
    path: 'homeCareBeds',
    icon: 'fa fa-book',
    name: 'homeCareBeds',
    label: '家庭养老床位',
    children: [{
        path: 'homeCareBeds/bedCompany',
        icon: 'el-icon-location',
        label: '施工单位',
        name: 'BedCompany',
      },
      {
        path: 'homeCareBeds/bedPerson',
        icon: 'el-icon-location',
        label: '人员信息',
        name: 'BedPerson',
      },
    ],
  }],
  menuFive: [{
    path: 'homeCare',
    icon: 'fa fa-book',
    name: 'HomeCare',
    label: '居家养老',
    children: [{
      path: 'serveOrgs',
      icon: 'el-icon-office-building',
      label: '服务企业',
      name: 'ServeOrgs',
    }],
  }],
  menuSix: [{
    path: 'nursingHome/callCenter',
    icon: 'el-icon-mic',
    label: '呼叫中心',
    name: 'CallCenter',
    children: [
      {
        path: 'callRecords',
        icon: 'el-icon-office-building',
        label: '通话记录',
        name: 'callRecords',
      },
      {
        path: 'serviceCall',
        icon: 'el-icon-office-building',
        label: '服务记录',
        name: 'serviceCall',
      },
      {
        path: 'missCall',
        icon: 'el-icon-office-building',
        label: '未接电话记录',
        name: 'missCall',
      },
      {
        path: 'callTable',
        icon: 'el-icon-office-building',
        label: '呼叫报表',
        name: 'callTable',
      }
    ],
  }],
  menuSeven: [{
    path: 'mechanismCare',
    icon: 'fa fa-book',
    name: 'mechanismCare',
    label: '机构养老',
    children: [{
        path: 'mechanismCare/datacenter',
        icon: 'el-icon-location',
        label: '数据中心',
        name: 'datacenter',
      },
      {
        path: 'mechanismCare/personManage',
        icon: 'el-icon-location',
        label: '机构管理',
        name: 'PersonsManage',
      },
      {
        path: 'mechanismCare/bed',
        icon: 'el-icon-location',
        label: '床位管理',
        name: 'BedInfo',
      },
      {
        path: 'mechanismCare/entry',
        icon: 'el-icon-location',
        label: '办理入住',
        name: 'EntryInfo',
      },
    ],
  }],
  menuEight: [{
    path: 'AgedEat/evaluationAgency',
    icon: 'el-icon-office-building',
    label: '评估机构',
    name: 'EvaluationAgency',
  }],
  menuNine: [{
    path: 'system',
    icon: 'fa fa-book',
    name: 'System',
    label: '系统管理',
    children: [{
        path: 'globalEvn',
        icon: 'el-icon-location',
        label: '抬头管理',
        name: 'GlobalEvn',
      },{
        path: 'dictType',
        icon: 'el-icon-location',
        label: '字典类型',
        name: 'DictType',
      },
      {
        path: 'dict',
        icon: 'el-icon-location',
        label: '数据字典',
        name: 'Dict',
      },
      {
        path: 'menu',
        icon: 'el-icon-location',
        label: '系统菜单',
        name: 'Menu',
      },
      {
        path: 'userManage',
        icon: 'el-icon-location',
        label: '用户管理',
        name: 'UserManage',
      },
      {
        path: 'community',
        icon: 'el-icon-location',
        label: '社区管理',
        name: 'Community',
      },
      {
        path: 'uploadFile',
        icon: 'el-icon-location',
        label: '文件管理',
        name: 'UploadFile',
      },
      {
        path: 'log',
        icon: 'el-icon-location',
        label: '访问日志',
        name: 'LogManage',
      },
    ],
  }],
  menuTen: [{
    path: 'volunteerManagement/volunteerInfo',
    icon: 'el-icon-mic',
    label: '志愿者管理',
    name: 'volunteerInfo',
    children: [
      {
        path: 'volunteerInfo',
        icon: 'el-icon-office-building',
        label: '志愿者档案',
        name: 'volunteerInfo',
      },
      {
        path: 'volunteerAct',
        icon: 'el-icon-office-building',
        label: '志愿者活动',
        name: 'volunteerAct',
      },
    ],
  }],
}
export default menu;
