<template>
<el-breadcrumb separator="/">
  <el-breadcrumb-item>{{$route.matched[1].meta.title}}</el-breadcrumb-item>
  <el-breadcrumb-item v-if="activeMenu">{{activeMenu.label}}</el-breadcrumb-item>
  <!-- <el-breadcrumb-item v-if="currTreeNode">{{currTreeNode.label}}</el-breadcrumb-item>
  <el-breadcrumb-item v-else>全部</el-breadcrumb-item> -->
</el-breadcrumb>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapState([
      "activeMenu",
      "currTreeNode"
    ]),
  },
}
</script>

<style>

</style>
