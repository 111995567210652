<template>
  <div class="wrapper">
    <div class="side-title">
      <!-- <img src="@/assets/images/minZhengLogo.png" alt="" /> -->
      <img src="@/assets/images/logo.png" alt="" />

      养老平台
    </div>
    <el-menu
      :default-active="tabName"
      class="el-menu-vertical-demo"
      background-color="#191a23"
      text-color="#fff"
      active-text-color="#fff"
      unique-opened
      @open="handleOpen"
      @close="handleClose"
      v-if="activeMenu"
    >
      <div v-for="(item, index) in newMenuList" :key="index">
        <el-menu-item :index="item.name" v-if="!item.children" @click="goToPage(item)">
          <i :class="item.icon"></i>
          <span>{{ item.label }}</span>
        </el-menu-item>
        <el-submenu :index="item.name" v-else>
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
          </template>

          <!-- 遍历二级菜单容器 -->
          <div v-for="(i, index) in item.children" :key="index">
            <!-- 判断二级菜单（没有三级菜单）-->
            <el-menu-item :index="i.name" v-if="!i.children" @click="goToPage(i)">
              <i :class="i.icon"></i>
              <span>{{ i.label }}</span>
            </el-menu-item>

            <!-- 判断二级菜单（有三级菜单）-->
            <el-submenu :index="i.name" v-if="i.children">
              <template slot="title">
                <i :class="i.icon"></i>
                <span>{{ i.label }}</span>
              </template>
              <el-menu-item :index="j.name" v-for="(j, index) in i.children" :key="index" @click="goToPage(j)">{{ j.label }}</el-menu-item>
            </el-submenu>
          </div>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import menu from '@/utils/menu'
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['isCollapse', 'activeMenu', 'tabName']),
    newMenuList () {
      if ('0' == sessionStorage.menu) {
        return menu.menuAll
      }
      if ('1' == sessionStorage.menu) {
        return menu.menuOne
      }
      if ('2' == sessionStorage.menu) {
        return menu.menuTwo
      }
      if ('3' == sessionStorage.menu) {
        return menu.menuThree
      }
      if ('4' == sessionStorage.menu) {
        return menu.menuFour
      }
      if ('5' == sessionStorage.menu) {
        return menu.menuFive
      }
      if ('6' == sessionStorage.menu) {
        return menu.menuSix
      }
      if ('7' == sessionStorage.menu) {
        return menu.menuSeven
      }
      if ('8' == sessionStorage.menu) {
        return menu.menuEight
      }
      if ('9' == sessionStorage.menu) {
        return menu.menuNine
      }
      if ('10' == sessionStorage.menu) {
        return menu.menuTen
      }
      return menu.menuAll
    }
  },
  created() {
  },
  methods: {
    ...mapMutations(['goToPage', 'removeTab']),
    hasPermission(roles, route) {
      if (route.name) {
        return roles.some((role) => route.name == role)
      } else {
        return true
      }
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    // goToPage(item) {
    //   this.$store.state.activeMenu = item;
    //   localStorage.activeMenu = JSON.stringify(item)
    //   this.$router.push({
    //     name: item.name
    //   })
    // }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  color: #fff;
  background: #191a23;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .side-title {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #101117;
    background: #191a23;
    height: 63px;
    line-height: 63px;
    font-size: 18px;
    overflow: hidden;
    img {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
  }
  .el-menu {
    height: 100%;
    overflow: auto;
    border: none;
    .el-menu-item.is-active {
      background-color: #2d8cf0 !important;
    }
    i {
      color: #fff;
      margin-right: 10px;
    }
  }
}
</style>
