<template>
<el-tabs v-model="tabName" type="card" closable @tab-remove="removeTab" @tab-click="change">
  <el-tab-pane :key="item.name" v-for="item in tabs" :label="item.label" :name="item.name">
  </el-tab-pane>
</el-tabs>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["tabs"]),
    tabName: {
      get() {
        return this.$store.state.tabName;
      },
      set(newValue) {
        this.$store.state.tabName = newValue;
      }
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    ...mapMutations(["goToPage", "removeTab"]),
    change(tab) {
      this.tabs.forEach(item => {
        if (item.name == tab.name) {
          this.goToPage(item);
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
