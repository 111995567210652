<template>
  <el-container>
    <el-aside width="256px" v-if="isCollapse">
      <Menu/>
    </el-aside>
    <el-container>
      <el-header v-show="!isShow">
        <Header/>
      </el-header>
      <el-container>
        <el-main>
          <Main/>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import Header from "./Header";
import Tabs from "./Tabs";
import Main from "./Main";
import Menu from "./Menu";
import Breadcrumb from "./Breadcrumb";

export default {
  components: {
    Header,
    Tabs,
    Main,
    Menu,
    Breadcrumb
  },
  data() {
    return {
      fade: ""
    }
  },
  computed: {
    ...mapState(["tabName"]),
    "isCollapse": {
      get() {
        return this.$store.state.isCollapse;
      },
      set(newValue) {
        this.$store.state.isCollapse = newValue;
      }
    },
    "isShow": {
      get() {
        const array = ['dataFlash', 'smartElderly', 'weekReport']
        let handleName = '';
        if (this.$store.state.tabName === '') {
          const url = window.location.href;             //获取当前url
          const dz_url = url.split('#')[1];                //获取#/之前的字符串
          const cs = dz_url.split('?')[0];                //获取?之后的参数字符串
          const cs_arr = cs.split('/');                    //参数字符串分割为数组
          handleName = cs_arr[cs_arr.length - 1]
        } else {
          handleName = this.$store.state.tabName
        }
        if (array.indexOf(handleName) !== -1) {
          return true
        } else {
          return false
        }
      }
    }
  },

  methods: {
    showMenu() {
      this.$store.state.isCollapse = !this.$store.state.isCollapse;
    },
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  height: 100%;
  overflow: hidden;

  .el-header {
    height: 64px;
    line-height: 64px;
    background: #fff;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    margin-bottom: 10px;
    overflow: hidden;
  }

  .el-aside {
    box-sizing: border-box;
  }

  .el-main {
    padding: 0;
    height: 100%;
    background: #fff;
    border-left: 1px solid #eee;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
</style>
