<template>
  <div class="breadcrumb">
    <i class="el-icon-s-operation" @click="showMenu"></i>
    <Breadcrumb />
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb";

export default {
  components: {
    Breadcrumb
  },
  computed: {
  },
  created() {
  },
  methods: {
    showMenu() {
      this.$store.state.isCollapse = !this.$store.state.isCollapse;
    },
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  i {
    float: left;
    font-size: 24px;
    margin-top: 2px;
    cursor: pointer;
  }
}
.el-breadcrumb {
  border-bottom: none !important;
}
</style>